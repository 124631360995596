<template>
  <div>
    <div class="form">
      <div class="formTitle center">金点子创意奖励办法</div>
      <div class="formItem row-start-center">
        <div class="itemName">姓名</div>
        <input/>
      </div>
      <div class="formItem row-start-center">
        <div class="itemName">联系方式</div>
        <input/>
      </div>
      <div class="formTextArea">
        <div class="areaName">建议原因</div>
        <div class="textArea" contenteditable="true"></div>
      </div>
      <div class="formTextArea">
        <div class="areaName">建议</div>
        <div class="textArea" contenteditable="true" placeholder=""></div>
      </div>
      <div class="formTextArea">
        <div class="areaName">其他</div>
        <div class="textArea" contenteditable="true"></div>
      </div>
    </div>
    <div class="role">
      <div class="row-start-start">
        <div class="roleName">奖励周期</div>
        <div class="roleContent">即日起至2022年12月31日止（暂行一年）</div>
      </div>
      <div class="row-start-start">
        <div class="roleName">内容及要求</div>
        <div class="roleContent">
          <div>1.要有创意、条理清晰，要能解决实际工作中的一些具体问题</div>
          <div>2.包括产品创新、营销创新、内部管理创新</div>
          <div>3.建议要具体到操作环节，切实可行</div>
          <div>4.合理化创意或建议不包含：夸夸其谈、无实质、无新意的建议；只指出问题、弊端或只提出建议，而无解决问题具体办法或思路；公认的事实或已被使用或采用的重复建议</div>
        </div>
      </div>
      <div class="row-start-start">
        <div class="roleName">申报流程</div>
        <div class="roleContent">可以个人或者两人以上的形式提交创意点子，以点子为奖励单元，不对参与人数进行奖励</div>
      </div>
      <div class="row-start-start">
        <div class="roleName">参与方式</div>
        <div class="roleContent">
          <div>1.正式会议（总办会或月度例会）</div>
          <div>2.公众号</div>
          <div>3.公司工作群</div>
        </div>
      </div>
      <div class="row-start-start">
        <div class="roleName">奖励措施</div>
        <div class="roleContent">金点子一经采用，给予投稿者相应的稿酬（采用当天，由综合部兑现），标准如下：社会人士：200元；内部员工：200元</div>
      </div>
      <div class="row-start-start">
        <div class="roleName">其他</div>
        <div class="roleContent">如因采纳建议而增加收入或者产品反响效果显著提高，经公司审核后，可追加奖励，奖励金额定为1万元以上，且不封顶，年底统一发放。</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Suggest",
  data(){
    return{
      username:'',
      phone:'',
      reason:'',
      measure:'',
      desc:'',
    }
  }
}
</script>

<style scoped>
.form {
  width: 15rem;
  padding: 0 .5rem;
}

.formTitle {
  font-weight: bold;
  margin: .6rem;
}

.formItem {
  margin-top: .6rem;
  padding: .4rem;
  font-size: .67rem;
  border-radius: .2rem;
  background-color: #FFFFFF;
}

.itemName {
  width: 3rem;
  font-size: .6rem;
  font-weight: bold;
  color: #00b453;

}

.formItem > input {
  flex: 1;
  border: none;
}

.formTextArea {
  margin-top: .6rem;
}

.areaName {
  margin-left: .4rem;
  font-size: .6rem;
  font-weight: bold;
  color: #00b453;
}

.formTextArea > .textArea {
  margin-top: .3rem;
  width: 14.4rem;
  min-height: 3.4rem;
  padding: .3rem;
  font-size: .67rem;
  border: none;
  outline: none;
  border-radius: .2rem;
  background-color: #FFFFFF;
}

.role {
  padding: 1rem;
  line-height: .8rem;
}
.role>div{
  margin-top: .5rem;
}

.roleName {
  width: 3rem;
  font-size: .5rem;
  font-weight: bold;
}

.roleContent {
  width: 11rem;
  font-size: .6rem;
}
</style>
